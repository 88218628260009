<template>
    <div class="row">
        <div class="col-12">
            <div class="row gy-3">
                <div class="col-12">
                    <h5 class="mb-0">Customer lookup</h5>
                </div>
                 <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row gy-3 align-items-end">
                                <div class="col-lg-4">
                                    <div class="">
                                        <label class="form-label">Cell Phone</label>
                                        <input type="text" v-maska data-maska="(###) ###-####" v-model="data.mobile" class="form-control"
                                        name="phone" placeholder="Phone" :class="{ 'is-invalid': v$.data.mobile.$error }">
                                        
                                        <div v-for="(item, index) in v$.data.mobile.$errors"
                                        :key="index" class="invalid-feedback">
                                        <span v-if="item.$message">{{ item.$message }}</span>
                                        </div>      
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="">
                                        <label class="form-label">Item Number</label>
                                        <input type="text" v-model="data.number" class="form-control"
                                        name="number" placeholder="Serial Number" :class="{ 'is-invalid': v$.data.number.$error }">
                                        <div v-for="(item, index) in v$.data.number.$errors"
                                        :key="index" class="invalid-feedback">
                                        <span v-if="item.$message">{{ item.$message }}</span>
                                        </div>      
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <button @click.prevent="submitData()" class="btn mb-0 w-100 btn-primary"  type="button">
                                        Search
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="item" class="col-12">
                    <div v-if="item.user" class="card bg-white overflow-hidden">
                        <div class="card-body">
                            <div class="d-sm-flex align-items-center">
                                <div class="position-relative d-inline-block mb-4 mb-sm-0 me-3">
                                    <img :src="absoluteUrl(item.user.image)" class="rounded-circle avatar-xl img-thumbnail" />
                                </div>
                                <div>
                                    <h5 class="fs-16 mb-1">
                                        {{item.user.first_name}} {{item.user.last_name}}
                                    </h5>
                                    <p class="mb-0"> <span class="">{{item.user.mobile}}</span></p>
                                    <p class="mb-0"> <span class="">{{item.user.email}}</span></p>
                                </div>
                            </div>
                        </div>
                        <div class="circles-container" ><div class="circle-back" ></div><div class="circle-front" ></div></div>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex align-items-center justify-content-start">
                                <div class="">
                                    <img v-if="item.device.image" style="width:3rem; height:3rem; margin-right:1rem;" :src="absoluteUrl(item.device.image)" />
                                    <div v-else class="avatar img-thumbnail me-2 flex-shrink-0">
                                        <div class="avatar-title border bg-light text-primary rounded-circle text-uppercase">
                                            {{item.device.model.charAt(0).toUpperCase()}}
                                        </div>
                                    </div>
                                </div> 
                                <div class="">
                                    <h5 class="card-title mb-0">{{item.title}}</h5>
                                </div>
                            </div>
                            <div class="row pt-3 g-2"> 
                                <div class="col-6"> 
                                    <span>Item ID</span>
                                    <h6> {{item.friendly_id}} </h6>
                                </div>
                                <div class="col-6"> 
                                    <span>Status</span>
                                    <div><span v-html="statusBadge(item.status)"> </span></div>
                                </div>
                                <div class="col-sm-6"> 
                                    <span>Model Name</span>
                                    <h6> {{item.device.model}} </h6>
                                </div> 
                                <div v-if="item.serial_number" class="col-sm-6"> 
                                    <span>Serial Number</span>
                                    <h6> {{item.serial_number}} </h6>
                                </div>
                                <div v-if="item.imei_number" class="col-sm-6"> 
                                    <span>Imei Number</span>
                                    <h6> {{item.imei_number}} </h6>
                                </div>
                                <div v-if="item.purchase_date" class="col-sm-6"> 
                                    <span>Purchase date</span>
                                    <h6> {{$filters.date(item.purchase_date)}} </h6>
                                </div>
                                <div v-if="item.purchase_price" class="col-sm-6"> 
                                    <span>Price</span>
                                    <h6> ${{$filters.money_format(item.purchase_price)}} </h6>
                                </div>
                                <div v-if="item.purchase_condition" class="col-sm-6"> 
                                    <span>Purchase condition</span>
                                    <h6> {{item.purchase_condition.replace(/_/g, ' ')}} </h6>
                                </div>
                                <div v-if="item.purchase_place" class="col-sm-6"> 
                                    <span>Purchase location</span>
                                    <h6> {{item.purchase_place}} </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="col-12">
                    <div class="card">
                        <div class="card-body text-center">
                            <span>No item to show</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required, minLength, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
    name: 'claim-lookup',
    setup() {
        return { v$: useVuelidate() };
    },
    data(){
        return {
            isLoading: false,
            data:{
                number: null,
                mobile: ""
            },
            item: null
        }
    },
    validations() {
        return {
            data:{
                mobile: {
                    required: helpers.withMessage("Phone is required", required),
                },
                number: {
                    required: helpers.withMessage("Item ID is required", required),
                },
            }
        }
    },
    computed:{
        activeUser(){
            return this.$store.state.AppActiveUser
        },
    },
    methods:{
        submitData(){
            this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                this.item = null
                this.data.mobile = this.$filters.unmaskMobile(this.data.mobile)
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.get(`/items/lookup?${new URLSearchParams(this.data).toString()}`)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue")
                    if(response.data.success){
                        this.item = response.data.data
                    }
                })
            });        
        },
    }
}
</script>

<style scoped>

</style>
